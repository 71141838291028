.matches-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 80%;
  height: fit-content;
  padding: 0.5rem;
  justify-content: left;
  align-items: center;
}

.badges-container{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
