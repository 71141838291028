
.table-container{
  max-height: 300px;
}

.matches-table th,
.matches-table tr {
  width: fit-content;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-align: center;
}

.matches-table th {
  font-size: 1.2rem;
  color: #f5803e;
}
.matches-table tr {
    height: 2rem;
    min-height: 2rem;
    margin-bottom: 1rem;
}

.matches-table thead{
    background-color: #061325;
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
}