.content-container {
  padding: 20px;
  text-align: center;
}

.report-name-input,
.column-name-input {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  background-color: #0e1c2f;
  color: white;
  border: 1px solid #ced4da;
  box-shadow: none;
}

.report-name-input:focus,
.column-name-input:focus {
  border-color: #a9a9a9;
}

.columns-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.column {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  position: relative;
}

.select-group {
  margin-top: 10px;
  text-align: left;
}

.select-group label {
  display: block;
  margin-bottom: 2px;
}

.select-field {
  display: block;
  margin: 2px 0 10px 0;
  padding: 10px;
  width: 100%;
}

.view-matches-button,
.add-column-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #2c7be5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.view-matches-button:hover,
.add-column-button:hover {

  &:not(:disabled) {
    background-color: #0056b3;
  }
}


.table-container {
  max-height: 800px;
}

.matches-table th,
.matches-table tr {
  width: fit-content;
  padding-right: 3rem;
  padding-left: 3rem;
  text-align: center;
}

.matches-table th {
  font-size: 1.2rem;
  color: #f5803e;
}

.matches-table tr {
  height: 2rem;
  min-height: 2rem;
  margin-bottom: 1rem;
}

.matches-table thead {
  background-color: #061325;
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}