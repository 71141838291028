.point-shots-container {
    position: relative;
}
.playing-shot{
    background-color: darkblue !important;
}

.active-circle {
    width: 16px;
    height: 16px;
    background-color: #f79965;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 5px;
    border: 2px solid #ccc;
}

.inactive-circle {
    width: 8px;
    height: 8px;
    background-color: #9a9a9a;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 5px;
}