.header-image {
  width: calc(100% + 30px);
  margin-left: -15px;
}

.content-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-self: center;

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: stretch;
  }

  .left-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .player-name {
      font-size: 35px;
      font-weight: 400;
      letter-spacing: 1.75px;
      /* font-family: 'TitlingGothicFB-Normal', sans-serif; */
    }

    .subheader {
      color: #D5AA2A;
      letter-spacing: 1.25px;
      font-weight: 300;
      font-size: 25px;
      /* font-family: 'TitlingGothicFB-Light', sans-serif; */
    }
  }

  .right-container {
    display: flex;
    gap: 32px;
    align-items: stretch;
    width: 60%;
    justify-content: end;
    height: 80px;
  }

  .right-container select {
    padding: 10px;
    font-size: 16px;
    border-radius: 9999px;
    border: 1px solid #ccc;
    height: 100%;
    flex: 1;
    border-right: 15px solid transparent;

  }

  .link-column {
    color: #facc15
  }
}