.button-group-item {
  padding: 0.5rem;
  cursor: pointer;
  border-top-width: 1px !important;
}

.button-group-item:focus {
  outline: none;
}

.button-group-item:disabled {
  cursor: not-allowed;
}
