.action-but {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
}

.enable-but:hover {
    color: #343a40 !important;
    background-color: #f8f9fa !important;
    cursor: pointer;
}

.danger-but {
    background-color: transparent !important;
    border-color: #e63757 !important;
    color: #e63757 !important;
}

.danger-but:hover {
    color: white !important;
    background-color: #e63757 !important;
    cursor: pointer;
}

.nav-link {
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 2px;
}