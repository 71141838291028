.table-auto {
    table-layout: auto;
    width: "80%";
    margin: 0 auto;
}

.table-auto th,
.table-auto td {
    white-space: nowrap;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    vertical-align: middle;
}